import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Box, VStack, Text, Image, Button, useToast, Flex } from '@chakra-ui/react';
import { AuthContext } from '../../../../contexts/AuthContext';
import axios from 'axios';

const GerarPix = () => {
  const { id } = useParams();
  const [pixData, setPixData] = useState(null);
  const toast = useToast();
  const { authInfo } = useContext(AuthContext);
  const queryParms = new URLSearchParams(window.location.search);

  // Mock data for now
  const mockPixData = {
    paymentId: "12345",
    qrCodeText: "00020126580014br.gov.bcb.pix0136a629532e-7831-4c76-a88f-9583c8f84696520400005303986540510.005802BR5925Test Store6009Sao Paulo62070503***6304D234",
    //qrCode: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAA..." // Add a base64 QR code image here
    qrCode: "https://cdn.pixabay.com/photo/2023/02/28/01/51/qr-code-7819653_1280.jpg"
  };

  useEffect(() => {
    // Quando a api estiver pronta é so descomentar o metodo abaixo e fazer as devidas configurações

    axios.post(`${process.env.REACT_APP_SERVIDOR}/cobrancas/${id}/payment`,
      { valor: queryParms.get('valor') },
      {
        headers: {
          "x-access-token": authInfo?.dataUser?.token,
        }
      })
      .then(response => {
        setPixData(response.data.pix);
      })
      .catch(error => {
        toast({
          title: "Erro ao gerar PIX",
          description: error.response?.data?.message || "Erro ao processar pagamento",
          status: "error",
          duration: 5000,
        });
      });


    // Using mock data for now
    //setPixData(mockPixData);
  }, [id]);

  const copyPixCode = () => {
    navigator.clipboard.writeText(pixData.qrCodeText);
    toast({
      title: "Código PIX copiado!",
      status: "success",
      duration: 2000,
    });
  };

  return (
    <Flex minH="100vh" flexDirection="column">
      <Text color="white" fontSize="24px" mb={6}>
        Pagamento via PIX
      </Text>

      <Box bg="#1F2630" p={8} borderRadius="lg" maxW="500px" mx="auto">
        <VStack spacing={6}>
          <Text color="white" fontSize="lg">
            Escaneie o QR Code ou copie o código PIX
          </Text>

          {pixData?.qrCode && (
            <Image
              src={`data:image/png;base64,${pixData.qrCode}`}
              alt="QR Code PIX"
              boxSize="300px"
              bg="white"
              p={4}
              borderRadius="md"
            />
          )}

          <Button
            colorScheme="blue"
            width="full"
            onClick={copyPixCode}
          >
            Copiar código PIX
          </Button>

          <Text color="white" fontSize="sm">
            ID do Pagamento: {pixData?.paymentId}
          </Text>
        </VStack>
      </Box>
    </Flex>
  );
};

export default GerarPix;
