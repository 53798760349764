import { useToast } from "@chakra-ui/react";

export const useToastError = () => {
  const toast = useToast();

  const showToast = (title, description, status = 'error') => {
    toast({
      title,
      description,
      status,
      duration: 3000,
      position: 'top-right',
      isClosable: true,
    });
  };

  return {
    error: (title, description) => showToast(title, description, 'error'),
    warning: (title, description) => showToast(title, description, 'warning'),
    success: (title, description) => showToast(title, description, 'success'),
  };
}

/* 
Example of usage:
const toast = useToastError();
toast.error('Error Title', 'Error Description');
toast.warning('Warning Title', 'Warning Description');
toast.success('Success Title', 'Success Description');
*/