import React, { useContext, useEffect, useState } from "react";
import "./Dashboard.css";
import axios from "axios";
import * as links from "../../../utils/links";
import { AuthContext } from "../../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import LoadingAction from "../../../themes/LoadingAction/LoadingAction";
import { Flex, Grid, Text, useMediaQuery, useToast } from "@chakra-ui/react";
import { FaSignal } from "react-icons/fa";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import WarningCard from '../../Main/Cliente/WarningCard/index';
import moment from "moment";

const CardWithWeeklyBarChart = ({ queryResultsDaily = [] }) => {
  const transformData = (results) => {
    const today = moment().endOf('day');
    const startOfWeek = moment().subtract(6, 'days').startOf('day');

    // Initialize data structure for last 7 days
    const weekData = {};
    for (let i = 0; i < 7; i++) {
      const currentDate = startOfWeek.clone().add(i, 'days').format('YYYY-MM-DD');
      weekData[currentDate] = {
        bank_transfer: 0,
        account_money: 0,
        credit_card: 0,
        debit_card: 0,
        CASH: 0
      };
    }

    // Fill with actual data
    if (Array.isArray(results)) {
      results.forEach(item => {
        if (weekData[item.pix_date]) {
          weekData[item.pix_date][item.tipo] = item.quantity;
        }
      });
    }

    // Transform to chart format
    const chartValues = Object.values(weekData);
    return {
      pix: chartValues.map(day => day.bank_transfer + day.account_money),
      credit: chartValues.map(day => day.credit_card),
      debit: chartValues.map(day => day.debit_card),
      cash: chartValues.map(day => day.CASH)
    };
  };

  const getDayLabels = () => {
    const startOfWeek = moment().subtract(6, 'days');
    return Array.from({ length: 7 }, (_, i) => {
      const date = startOfWeek.clone().add(i, 'days');
      const isToday = date.isSame(moment(), 'day');
      return isToday ? 'HOJE' : date.format('DD/MM');
    });
  };

  const chartData = transformData(queryResultsDaily);

  const options = {
    chart: {
      type: "column",
      backgroundColor: "transparent",
    },
    title: {
      text: "Transações dos Últimos 7 Dias",
      style: {
        color: '#ffffff'
      }
    },
    xAxis: {
      categories: getDayLabels(),
      labels: {
        style: {
          color: "#ffffff",
        },
      },
    },
    yAxis: {
      title: {
        text: "Quantidade de Transações",
        style: {
          color: "#ffffff"
        }
      },
      labels: {
        style: {
          color: "#ffffff",
        },
      },
    },
    series: [
      {
        name: "PIX",
        data: chartData.pix,
        color: "#4CAF50"
      },
      {
        name: "Débito",
        data: chartData.debit,
        color: "#2196F3"
      },
      {
        name: "Crédito",
        data: chartData.credit,
        color: "#FFC107"
      },
      {
        name: "Espécie",
        data: chartData.cash,
        color: "#9C27B0"
      }
    ],
    legend: {
      itemStyle: {
        color: "#ffffff",
      },
    },
    plotOptions: {
      column: {
        borderRadius: 5,
        borderWidth: 0,
        groupPadding: 0.2,
        dataLabels: {
          enabled: true,
          style: {
            color: "#ffffff",
            textOutline: "none",
            fontSize: "12px"
          }
        }
      }
    }
  };

  return (
    <div className="chart-container">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};
const CardWithLineChart = ({ title, categories, series }) => {
  const options = {
    chart: {
      type: "line",
      backgroundColor: "transparent",
    },
    title: {
      text: "",
    },
    xAxis: {
      categories,
      labels: {
        style: {
          color: "#ffffff",
        },
      },
    },
    yAxis: {
      title: {
        text: "",
      },
      labels: {
        style: {
          color: "#ffffff",
        },
      },
    },
    series,
    legend: {
      itemStyle: {
        color: "#ffffff",
      },
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: false,
          style: {
            color: "#ffffff",
          },
        },
      },
    },
  };

  return (
    <Flex
      width="100%"
      gap="20px"
      padding="10px 20px"
      borderRadius="20px"
      backgroundColor="#1F2630"
      flexDirection="column"
      minH="350px"
    >
      <Text color="white" fontSize="18px">
        {title}
      </Text>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </Flex>
  );
};

const CardWithBarChart = ({ title, categories, dashboardStats }) => {
  // Transform the data from the backend
  const transformData = (stats) => {
    if (!stats) return {
      pix: new Array(12).fill(0),
      credit: new Array(12).fill(0),
      debit: new Array(12).fill(0),
      cash: new Array(12).fill(0)
    };

    const data = {
      pix: [],
      credit: [],
      debit: [],
      cash: []
    };

    for (let i = 0; i < 12; i++) {
      const monthData = stats[i] || {};
      // Sum bank_transfer and account_money for PIX
      data.pix.push((monthData.bank_transfer || 0) + (monthData.account_money || 0));
      data.credit.push(monthData.credit_card || 0);
      data.debit.push(monthData.debit_card || 0);
      data.cash.push(monthData.CASH || 0);
    }

    return data;
  };

  const chartData = transformData(dashboardStats);

  const options = {
    chart: {
      type: "column",
      backgroundColor: "transparent",
    },
    title: {
      text: "Transações por Método de Pagamento",
      style: {
        color: '#ffffff'
      }
    },
    xAxis: {
      categories,
      labels: {
        style: {
          color: "#ffffff",
        },
      },
    },
    yAxis: {
      title: {
        text: "Quantidade de Transações",
        style: {
          color: "#ffffff"
        }
      },
      labels: {
        style: {
          color: "#ffffff",
        },
      },
    },
    series: [
      {
        name: "PIX",
        data: chartData.pix,
        color: "#4CAF50"
      },
      {
        name: "Débito",
        data: chartData.debit,
        color: "#2196F3"
      },
      {
        name: "Crédito",
        data: chartData.credit,
        color: "#FFC107"
      },
      {
        name: "Espécie",
        data: chartData.cash,
        color: "#9C27B0"
      }
    ],
    legend: {
      itemStyle: {
        color: "#ffffff",
      },
    },
    plotOptions: {
      column: {
        borderRadius: 5,
        borderWidth: 0,
        groupPadding: 0.2,
        dataLabels: {
          enabled: true,
          style: {
            color: "#ffffff",
            textOutline: "none",
            fontSize: "12px"
          }
        }
      }
    }
  };

  return (
    <div className="chart-container">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};


const InformationCard = ({ title, value, signalColor }) => {
  return (
    <Flex
      width="100%"
      gap="20px"
      padding="10px 20px"
      borderRadius="20px"
      backgroundColor="#1F2630"
    >
      <Flex flexDirection="column" flex={1} justifyContent="space-between">
        <Text color="white" fontSize="18px">
          {title}
        </Text>
        <Text color="white" fontSize="22px" fontWeight="700">
          {value}
        </Text>
      </Flex>
      <Flex alignItems="center" justifyContent="flex-end" flex={1}>
        <FaSignal fontSize="36px" color={signalColor} />
      </Flex>
    </Flex>
  );
};

const FilterBox = ({ text, isActive, onClick }) => {
  return (
    <Flex
      padding="10px 20px"
      justifyContent="center"
      alignItems="center"
      borderRadius="8px"
      backgroundColor="#212530"
      color="white"
      onClick={onClick}
      border={isActive && "1px solid white"}
      cursor="pointer"
    >
      {text}
    </Flex>
  );
};

const DashboardFornecedor = (props) => {
  const { setDataUser, loading, authInfo, setNotiMessage } =
    useContext(AuthContext);
  const { dataUser } = authInfo;
  let navigate = useNavigate();
  const token = authInfo?.dataUser?.token;
  const premiumExpiration = authInfo?.dataUser?.premiumExpiration ?? null;
  const hasData = !!authInfo?.dataUser?.hasData;
  const [favorites, setFavorites] = useState([]);
  const [meusFits, setMeusFits] = useState(null);
  const [totalCanais, setTotalCanais] = useState(null);
  const [totalFornecedores, setTotalFornecedores] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [dataCurrentDetail, setDataCurrentDetail] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState("today");
  const [machines, setMachines] = useState([]);
  const [todosPagamentos, setTodosPagamentos] = useState([]);
  const [filteredPagamentos, setFilteredPagamentos] = useState([]);
  const [dashboardStats, setDashboardStats] = useState(null);

  const [isLargerThan800] = useMediaQuery("(min-width: 800px)");

  const toast = useToast();

  // useEffect(() => {
  //     dataData();
  // }, [])

  const fetchDashboard = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVIDOR}/dashboard-stats`, {
        headers: {
          "x-access-token": token,
          "content-type": "application/json",
        }
      })
      setDashboardStats(response.data);
    } catch (error) {
      toast({
        title: "Erro ao carregar dados",
        description: "Ocorreu um erro ao carregar os dados. Por favor, tente novamente mais tarde.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }

  useEffect(() => {
    dataData();
    fetchDashboard();

    const intervalId = setInterval(() => {
      dataData();
    }, 60000);

    // Limpar o intervalo quando o componente for desmontado para evitar vazamento de memória
    return () => clearInterval(intervalId);
  }, []);

  const dataData = () => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_SERVIDOR}/maquinas`, {
        headers: {
          "x-access-token": token,
          "content-type": "application/json",
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setIsLoading(false);
          setTotalFornecedores(res.data);
        } else {
          throw new Error();
        }
      })
      .catch((err) => {
        setIsLoading(false);
        if ([401, 403].includes(err.response.status)) {
          // setNotiMessage('A sua sessão expirou, para continuar faça login novamente.');
          setNotiMessage({
            type: "error",
            message:
              "A sua sessão expirou, para continuar faça login novamente.",
          });
          setDataUser(null);
        }
      });
  };

  useEffect(() => {
    const fetchMaquinas = async (maquinas) => {
      const promises = [];

      maquinas.forEach((maquina) => {
        const promise = axios
          .get(`${process.env.REACT_APP_SERVIDOR}/pagamentos/${maquina.id}`, {
            headers: {
              "x-access-token": token,
              "content-type": "application/json",
            },
          })
          .then((response) => {
            return {
              nome: maquina.nome,
              descricao: maquina.descricao,
              status: maquina.status,
              payments: response.data,
            };
          })
          .catch((error) => {
            console.error(
              `Erro ao buscar a máquina ${maquina.nome} do cliente ${maquina.nome}:`,
              error
            );
            return null; // Retorna null em caso de erro
          });

        promises.push(promise);
      });

      const resultados = await Promise.all(promises);

      return resultados;
    };

    fetchMaquinas(totalFornecedores).then((resultados) => {
      setMachines(resultados);
    });
  }, [token, totalFornecedores]);

  const handleMaquinaClick = (id, nome, storeId, pulso, estoque, descricao) => {
    const maquinaInfos = { nome, storeId, pulso, estoque, descricao };
    navigate(`${links.FORNECEDOR_SEARCH_CANAIS}/${id}`, {
      state: maquinaInfos,
    });
  };

  const availableModules = totalFornecedores.filter(
    ({ status }) => status !== "OFFLINE"
  );

  const valorTotalVendas = todosPagamentos
    .filter(({ estornado }) => !estornado)
    .reduce((acc, curr) => {
      return acc + Number(curr.valor);
    }, 0);

  useEffect(() => {
    const getRows = () => {
      const pagamentos = [];
      machines.forEach(({ nome, descricao, payments }) => {
        if (payments && payments.pagamentos) {
          payments.pagamentos.forEach((pagamento) => {
            pagamentos.push({ cliente: nome, descricao, ...pagamento });
          });
        }
      });

      let filtered = pagamentos;

      // Verifica o filtro selecionado
      if (selectedFilter !== "all") {
        // Usar moment para lidar corretamente com o fuso horário
        const now = moment();

        filtered = pagamentos.filter(({ data }) => {
          if (!data) return false;

          // Converter a data do pagamento para moment
          const pagamentoDate = moment(data);

          if (selectedFilter === "today") {
            // Verificar se a data do pagamento é hoje (usando moment.isSame)
            return pagamentoDate.isSame(now, 'day');
          } else if (selectedFilter === "weekly") {
            // Verificar se a data do pagamento está nos últimos 7 dias
            const weekAgo = moment().subtract(7, 'days');
            return pagamentoDate.isSameOrAfter(weekAgo, 'day');
          } else if (selectedFilter === "month") {
            // Verificar se a data do pagamento está no mês atual
            return pagamentoDate.isSame(now, 'month');
          }

          return true;
        });
      }

      console.log("Filtro selecionado:", selectedFilter);
      console.log("Total de pagamentos:", pagamentos.length);
      console.log("Pagamentos filtrados para", selectedFilter, ":", filtered.length);

      setTodosPagamentos(pagamentos);
      setFilteredPagamentos(filtered);
    };

    getRows();
  }, [machines, selectedFilter]);





  /* useEffect(() => {
    const getRows = () => {
      const pagamentos = [];
      machines.forEach(({ nome, descricao, payments }) => {
        payments.pagamentos.forEach((pagamento) => {
          pagamentos.push({ cliente: nome, descricao, ...pagamento });
        });
      });

      let filtered = pagamentos;

      // Verifica o filtro selecionado
      if (selectedFilter !== "all") {
        const now = new Date();
        let startDate;

        if (selectedFilter === "today") {
          startDate = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate()
          );
        } else if (selectedFilter === "weekly") {
          startDate = new Date(now);
          startDate.setDate(now.getDate() - 7);
        } else if (selectedFilter === "month") {
          startDate = new Date(now.getFullYear(), now.getMonth(), 1);
        }

        filtered = pagamentos.filter(({ data }) => {
          const pagamentoDate = new Date(data);
          return pagamentoDate >= startDate;
        });
      }

      setTodosPagamentos(pagamentos); // Todos os pagamentos
      setFilteredPagamentos(filtered); // Pagamentos filtrados
    };

    getRows();
  }, [machines, selectedFilter]); */

  return (
    <Flex width="100%" flexDirection="column" gap="20px">
      {isLoading && <LoadingAction />}
      {/* <div className="WarningMsg">
                    {dataUser.warningMsg}
                </div> */}
      {dashboardStats?.existePagamentoPendente && (
        <WarningCard data={dashboardStats?.existePagamentoPendente.dataVencimento} />
      )}
      <Flex width="100%" gap="10px">
        <FilterBox
          text="Hoje"
          isActive={selectedFilter === "today"}
          onClick={() => setSelectedFilter("today")}
        />
        <FilterBox
          text="7 dias"
          isActive={selectedFilter === "weekly"}
          onClick={() => setSelectedFilter("weekly")}
        />
        <FilterBox
          text="30 dias"
          isActive={selectedFilter === "month"}
          onClick={() => setSelectedFilter("month")}
        />
        <FilterBox
          text="Todos"
          isActive={selectedFilter === "all"}
          onClick={() => setSelectedFilter("all")}
        />
      </Flex>
      <Grid
        gridTemplateColumns={
          isLargerThan800 ? "repeat(3, 1fr)" : "repeat(1, 1fr)"
        }
        gridGap="20px"
      >
        <InformationCard
          title="Total de vendas"
          value={Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          }).format(
            filteredPagamentos
              .filter(({ estornado }) => !estornado)
              .reduce((acc, curr) => acc + Number(curr.valor), 0)
          )}
          signalColor="#48B786"
        />
        <InformationCard
          title="Número de vendas"
          value={
            filteredPagamentos.filter(({ estornado }) => !estornado).length
          }
          signalColor="#49CBD8"
        />
        <InformationCard
          title="Módulos online"
          value={availableModules.length}
          signalColor="#E3BA67"
        />
      </Grid>
      <CardWithBarChart
        categories={[
          "Jan", "Fev", "Mar", "Abr", "Mai", "Jun",
          "Jul", "Ago", "Set", "Out", "Nov", "Dez"
        ]}
        dashboardStats={dashboardStats?.stats}
      />


      <CardWithWeeklyBarChart queryResultsDaily={dashboardStats?.queryResultsDaily ?? []} />

    </Flex>
  );
};

export default DashboardFornecedor;
