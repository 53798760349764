import React, { useContext, useEffect, useState } from 'react'
import { Flex, Grid, Text, useMediaQuery, useToast, Button, Box, Input } from "@chakra-ui/react"
import { FaPercent } from "react-icons/fa"
import axios from 'axios'
import { AuthContext } from '../../../contexts/AuthContext'
import { useToastError } from '../../../utils/toastError'
import { set } from 'lodash'

const InformationCard = ({ title, value, onChange }) => {
  return (
    <Flex
      width="100%"
      gap="20px"
      padding="10px 20px"
      borderRadius="20px"
      backgroundColor="#1F2630"
    >
      <Flex flexDirection="column" flex={1} justifyContent="space-between">
        <Text color="white" fontSize="18px">
          {title}
        </Text>
        <input
          type="number"
          value={value}
          onChange={onChange}
          style={{
            backgroundColor: 'transparent',
            border: 'none',
            borderBottom: '1px solid white',
            color: 'white',
            fontSize: '22px',
            fontWeight: '700',
            width: '100px',
            outline: 'none'
          }}
        />
      </Flex>
      <Flex alignItems="center" justifyContent="flex-end" flex={1}>
        <FaPercent fontSize="36px" color="#48B786" />
      </Flex>
    </Flex>
  )
}

export default function Financeiro() {
  const [isLargerThan800] = useMediaQuery("(min-width: 800px)")
  const [taxaCredito, setTaxaCredito] = useState(0)
  const [taxaDebito, setTaxaDebito] = useState(0)
  const [taxaPix, setTaxaPix] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingDataCobranca, setIsLoadingDataCobranca] = useState(false)
  const [dataCobranca, setDataCobranca] = useState('')
  const toast = useToast()

  const { authInfo } = useContext(AuthContext);
  const token = authInfo?.dataUser?.token;

  const toastError = useToastError()

  const handleSaveDataCobranca = async () => {
    setIsLoadingDataCobranca(true)

    if (dataCobranca > 28) {
      toastError.error("Data de cobrança não pode ser maior que 28")
      setIsLoadingDataCobranca(false)
      return
    }

    if (dataCobranca < 3) {
      toastError.error("Data de cobrança não pode ser menor que 3")
      setIsLoadingDataCobranca(false)
      return
    }

    const data = {
      'configuracoes': [
        { 'dataCobranca': dataCobranca }
      ]
    }

    try {
      const response = await axios.put(`${process.env.REACT_APP_SERVIDOR}/configuracao-v2`, data, {
        headers: {
          "x-access-token": token,
          "content-type": "application/json",
        },
      })
      setDataCobranca(response.data.configuracoes.dataCobranca)
      toastError.success("Dados salvos com sucesso!")
      setIsLoadingDataCobranca(false)
    } catch (error) {
      console.log("Erro ao salvar dados de cobrança:", error)
      toastError.error("Erro ao salvar dados")
      setIsLoadingDataCobranca(false)
    }
  }

  const handleSaveTaxas = async () => {
    setIsLoading(true)

    try {
      // Simulando chamada para API
      await new Promise(resolve => setTimeout(resolve, 1500))

      // Simulando objeto para enviar ao backend
      const taxasData = {
        taxaCredito,
        taxaDebito,
        taxaPix
      }

      const response = await axios.put(`${process.env.REACT_APP_SERVIDOR}/configuracao`, taxasData, {
        headers: {
          "x-access-token": token,
          "content-type": "application/json",
        }
      })

      console.log(`Resposta da atualização: ${response.data}`)

      toast({
        title: "Taxas atualizadas!",
        description: "As novas taxas foram salvas com sucesso.",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right"
      })
    } catch (error) {
      toast({
        title: "Erro ao salvar",
        description: "Não foi possível atualizar as taxas.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right"
      })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const fetchTaxas = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVIDOR}/configuracao`, {
          headers: {
            "x-access-token": token,
            "content-type": "application/json",
          }
        });

        const configs = response.data;
        const creditoConfig = configs.find(config => config.chave === 'taxaCredito');
        const debitoConfig = configs.find(config => config.chave === 'taxaDebito');
        const pixConfig = configs.find(config => config.chave === 'taxaPix');
        const dataCobranca = configs.find(config => config.chave === 'dataCobranca');

        setTaxaCredito(creditoConfig ? Number(creditoConfig.valor) : 0);
        setTaxaDebito(debitoConfig ? Number(debitoConfig.valor) : 0);
        setTaxaPix(pixConfig ? Number(pixConfig.valor) : 0);
        setDataCobranca(dataCobranca ? dataCobranca.valor : '');
      } catch (error) {
        toast({
          title: "Erro ao carregar taxas",
          description: "Não foi possível carregar as taxas configuradas.",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top-right"
        });
      }
    };
    fetchTaxas();
  }, [token, toast]);

  return (
    <Flex width="100%" height="100vh" flexDirection="column" gap="20px">
      <Grid
        gridTemplateColumns={isLargerThan800 ? "repeat(3, 1fr)" : "repeat(1, 1fr)"}
        gridGap="20px"
      >
        <InformationCard
          title="Taxa para Crédito"
          value={taxaCredito}
          onChange={(e) => setTaxaCredito(e.target.value)}
        />
        <InformationCard
          title="Taxa para Débito"
          value={taxaDebito}
          onChange={(e) => setTaxaDebito(e.target.value)}
        />
        <InformationCard
          title="Taxa para PIX"
          value={taxaPix}
          onChange={(e) => setTaxaPix(e.target.value)}
        />
      </Grid>

      <Flex justifyContent="flex-end" mt={4}>
        <Button
          colorScheme="green"
          isLoading={isLoading}
          loadingText="Salvando..."
          onClick={handleSaveTaxas}
        >
          Salvar Taxas
        </Button>
      </Flex>

      <Flex
        width="100%"
        padding="20px"
        borderRadius="20px"
        backgroundColor="#1F2630"
        direction="column"
        mt={4}
      >
        <Flex direction="column" mb={4}>
          <Text color="white" fontSize="18px">
            Data de Cobrança das Taxas
          </Text>
          <input
            type="number"
            value={dataCobranca}
            onChange={(e) => setDataCobranca(e.target.value)}
            max="28"
            min="1"
            style={{
              backgroundColor: 'transparent',
              border: 'none',
              borderBottom: '1px solid white',
              color: 'white',
              fontSize: '22px',
              fontWeight: '700',
              width: '100px',
              outline: 'none'
            }}
          />
        </Flex>

        <Flex justifyContent="flex-end">
          <Button
            colorScheme="green"
            isLoading={isLoadingDataCobranca}
            loadingText="Salvando..."
            onClick={(e) => handleSaveDataCobranca(e.target.value)}
          >
            Salvar data de Cobrança
          </Button>
        </Flex>
      </Flex>
    </Flex>
  )
}