import React from 'react'
import { Flex, Text, Link } from '@chakra-ui/react'

export default function WarningCard({ data }) {
  const calculateRemainingDays = () => {
    const today = new Date()
    const dueDate = new Date(data)
    const diffTime = dueDate - today
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
    return diffDays
  }

  return (
    <Flex
      width="100%"
      bg="#FF9800"
      p={4}
      borderRadius="md"
      alignItems="center"
      gap={3}
    >
      <Flex direction="column">
        <Text color="white" fontWeight="bold">
          Pagamento pendente, {calculateRemainingDays()} dias faltando!
        </Text>
        <Text color="white">
          Pague agora ou sua conta será cancelada:{' '}
          <Link
            href="/cobrancas-cliente"
            textDecoration="underline"
            color="white"
            fontWeight="bold"
          >
            clique aqui para ir para tela de pagamentos
          </Link>
        </Text>
      </Flex>
    </Flex>
  )
}
