import React, { useContext, useEffect, useState } from 'react'
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Flex,
} from '@chakra-ui/react'
import CobrancasTable from './CobrancasTable'
import axios from 'axios'
import { useToastError } from '../../../../utils/toastError';
import { AuthContext } from '../../../../contexts/AuthContext';

export default function Cobrancas() {
  const [cobrancas, setCobrancas] = useState([]);
  const [dataCobranca, setDataCobranca] = useState('')

  const { authInfo } = useContext(AuthContext);
  const token = authInfo?.dataUser?.token;
  const toastError = useToastError()

  const fetchCobrancas = async () => {
    try {
      const cobrancas = await axios.get(`${process.env.REACT_APP_SERVIDOR}/minhas-cobrancas`, {
        headers: {
          "x-access-token": token,
          "content-type": "application/json",
        },
      })

      setCobrancas(cobrancas.data.cobrancas)
    } catch (error) {
      console.log(`Erro ao buscar cobranças: ${error}`)
      toastError.error("Erro ao buscar cobranças")
    }
  }

  const fetchConfiguracoes = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVIDOR}/configuracao`)
      const dataCobrancaConfig = response.data.find(config => config.chave === 'dataCobranca')
      setDataCobranca(dataCobrancaConfig.valor)
    } catch (error) {
      console.log(`Erro ao buscar cobranças: ${error}`)
      toastError.error("Erro ao buscar cobranças")
    }
  }
  useEffect(() => {
    fetchCobrancas()
    fetchConfiguracoes()
  }, [])

  return (
    <Flex flexDirection="column" minH="100vh">
      <Tabs style={{ height: '100%' }}>
        <TabList borderColor="#1F2630">
          <Tab
            color="white"
            _selected={{ borderColor: "white" }}
            fontSize="14px"
          >
            Todas as Cobranças{" "}
            <span
              style={{
                marginLeft: "10px",
                padding: "5px 10px",
                backgroundColor: "#01B0D1",
                borderRadius: "8px",
                fontSize: "12px",
              }}
            >
              {cobrancas.length}
            </span>
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <CobrancasTable pagamentos={cobrancas} dataCobranca={dataCobranca} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  )
}