import React, { useContext, useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Flex,
  useToast,
  Button,
  ButtonGroup,
  Box,
} from '@chakra-ui/react';
import axios from 'axios';
import { AuthContext } from '../../../../../contexts/AuthContext';
import LoadingAction from '../../../../../themes/LoadingAction/LoadingAction';
import { Coins } from 'lucide-react';

export default function CobrancasCliente({ isOpen, onClose, clientId }) {
  const [cobrancas, setCobrancas] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const { authInfo } = useContext(AuthContext);
  const token = authInfo?.dataUser?.token;
  const itemsPerPage = 5;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = cobrancas.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(cobrancas.length / itemsPerPage);

  const toast = useToast();

  const formatarData = (data) => {
    if (!data) return '-';
    return new Date(data).toLocaleDateString('pt-BR');
  };

  const formatarValor = (valor) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    }).format(valor);
  };

  const formatarMetodo = (metodo) => {
    if (!metodo) return '-';
    const metodos = {
      'PIX': 'PIX',
      'CREDIT_CARD': 'Cartão de Crédito'
    };
    return metodos[metodo] || metodo;
  };

  useEffect(() => {
    if (isOpen && clientId) {
      loadCobrancas();
    }
  }, [isOpen, clientId]);

  const handlePagarConta = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVIDOR}/pagar-conta-cliente/${clientId}`, {
        headers: {
          "x-access-token": token,
          "content-type": "application/json",
        },
      })
      const message = response.data.message;
      toast({
        title: "Sucesso",
        description: message,
        status: "success",
        duration: 3000,
        position: "top",
        isClosable: true,
      });
    } catch (error) {
      console.log(`Erro ao pagar conta: ${error}`);
      toast({
        title: "Erro",
        description: "Erro ao pagar conta",
        status: "error",
        duration: 3000,
        position: "top",
        isClosable: true,
      });
    }
  }

  const loadCobrancas = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVIDOR}/cobrancas-cliente/${clientId}`,
        {
          headers: {
            'x-access-token': token,
            'content-type': 'application/json',
          },
        }
      );

      // Ordenação: pendentes primeiro (pagou === false) e depois por data
      const cobrancasOrdenadas = response.data.sort((a, b) => {
        if (a.pagou === b.pagou) {
          return new Date(b.data) - new Date(a.data);
        }
        return a.pagou ? 1 : -1;
      });

      setCobrancas(cobrancasOrdenadas);
    } catch (error) {
      console.error('Erro ao carregar cobranças:', error);
    } finally {
      setIsLoading(false);
    }
  };


  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent backgroundColor="#1a202c">
        <ModalHeader color="white" display="flex" justifyContent="space-between" alignItems="center">
          <Text>Cobranças do Cliente</Text>
          <Button
            leftIcon={<Coins />}
            colorScheme="blue"
            size="sm"
            onClick={handlePagarConta}
            marginTop={"10%"}
          >
            Pagar Última Cobrança
          </Button>
        </ModalHeader>
        <ModalCloseButton color="white" />
        <ModalBody pb={6}>
          {isLoading ? (
            <LoadingAction />
          ) : (
            <>
              <Box overflowX="auto">
                <Table variant="simple" size="sm">
                  <Thead>
                    <Tr>
                      <Th borderColor="#1F2630" color="white" whiteSpace="nowrap">Data</Th>
                      <Th borderColor="#1F2630" color="white" whiteSpace="nowrap">Vencimento</Th>
                      <Th borderColor="#1F2630" color="white" whiteSpace="nowrap">Valor</Th>
                      <Th borderColor="#1F2630" color="white" whiteSpace="nowrap">Status</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {currentItems.map((cobranca) => (
                      <Tr key={cobranca.id}>
                        <Td borderColor="#1F2630" color="white" whiteSpace="nowrap">
                          {formatarData(cobranca.data)}
                        </Td>
                        <Td borderColor="#1F2630" color="white" whiteSpace="nowrap">
                          {formatarData(cobranca.dataVencimento)}
                        </Td>
                        <Td borderColor="#1F2630" color="white" whiteSpace="nowrap">
                          {formatarValor(cobranca.total)}
                        </Td>
                        <Td borderColor="#1F2630" whiteSpace="nowrap">
                          <Flex
                            bg={cobranca.pagou ? 'green.500' : 'red.500'}
                            px={3}
                            py={1}
                            borderRadius="md"
                            width="fit-content"
                          >
                            <Text color="white">
                              {cobranca.pagou ? 'PAGO' : 'PENDENTE'}
                            </Text>
                          </Flex>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
              <Flex justifyContent="center" mt={4}>
                <ButtonGroup>
                  <Button
                    onClick={() => setCurrentPage(prev => prev - 1)}
                    isDisabled={currentPage === 1}
                    colorScheme="blue"
                    size="sm"
                  >
                    Anterior
                  </Button>
                  <Text color="white" alignSelf="center" mx={4}>
                    Página {currentPage} de {totalPages}
                  </Text>
                  <Button
                    onClick={() => setCurrentPage(prev => prev + 1)}
                    isDisabled={currentPage === totalPages}
                    colorScheme="blue"
                    size="sm"
                  >
                    Próxima
                  </Button>
                </ButtonGroup>
              </Flex>
            </>
          )}
        </ModalBody>      </ModalContent>
    </Modal>
  );
}