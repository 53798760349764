import React from 'react'
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Button,
  HStack
} from '@chakra-ui/react'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import moment from 'moment'
import 'moment/locale/pt-br'

const formatarData = (data) => {
  try {
    return format(new Date(data), "dd/MM/yyyy")
  } catch (error) {
    return "--"
  }
}

moment.locale('pt-br')

export const MESES = {
  'January': 'Janeiro',
  'February': 'Fevereiro',
  'March': 'Março',
  'April': 'Abril',
  'May': 'Maio',
  'June': 'Junho',
  'July': 'Julho',
  'August': 'Agosto',
  'September': 'Setembro',
  'October': 'Outubro',
  'November': 'Novembro',
  'December': 'Dezembro'
}


const formatarMetodo = (metodo) => {
  return metodo === 'CREDIT_CARD' ? 'Cartão de Crédito' : metodo
}

export default function CobrancasTable({ pagamentos, dataCobranca = "" }) {
  return (
    <TableContainer minH="500px">
      <Table size="sm">
        <Thead>
          <Tr>
            <Th borderColor="#1F2630" color="white">Descrição</Th>
            <Th borderColor="#1F2630" color="white">Valor</Th>
            <Th borderColor="#1F2630" color="white">Data Vencimento</Th>
            <Th borderColor="#1F2630" color="white">Status</Th>
            <Th borderColor="#1F2630" color="white">Ações</Th>
          </Tr>
        </Thead>
        <Tbody>
          {pagamentos.map((pagamento) => (
            <Tr key={pagamento.id}>
              <Td color="white" borderColor="#1F2630">
                {moment(pagamento.dataVencimento)
                  .subtract(1, 'month')
                  .format('MMMM')
                  .replace(/^\w/, (c) => c.toUpperCase()) || 'Janeiro'}
              </Td>
              <Td color="white" borderColor="#1F2630">
                R$ {parseFloat(pagamento.total).toFixed(2)}
              </Td>
              <Td color="white" borderColor="#1F2630">
                {formatarData(new Date(pagamento.dataVencimento).setDate(parseInt(dataCobranca)))}
              </Td>
              <Td borderColor="#1F2630">
                <span
                  style={{
                    padding: "5px 10px",
                    backgroundColor: pagamento.pagou ? "#21BD52" : "#F45031",
                    borderRadius: "8px",
                    fontSize: "12px",
                    color: "white"
                  }}
                >
                  {pagamento.pagou ? 'Pago' : 'Pendente'}
                </span>
              </Td>
              <Td borderColor="#1F2630">
                {!pagamento.pagou && (
                  <HStack spacing={2}>
                    <Button
                      size="sm"
                      colorScheme="blue"
                      onClick={() => window.open(`/gerar-pix/${pagamento.id}?valor=${Number(pagamento.total).toFixed(2)}`)}
                    >
                      Pagar com PIX
                    </Button>
                  </HStack>
                )}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  )
}