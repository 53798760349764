import {
  Box,
  Button,
  Flex,
  Tab,
  Table,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { Badge, Tag } from "antd";
import { format } from "date-fns";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";

export default function Vendas() {
  const [machines, setMachines] = useState([]);
  const [totalFornecedores, setTotalFornecedores] = useState([]);
  const [todosPagamentos, setTodosPagamentos] = useState([]);

  /* valores de taxa */
  const [taxaCredito, setTaxaCredito] = useState(0)
  const [taxaDebito, setTaxaDebito] = useState(0)
  const [taxaPix, setTaxaPix] = useState(0)

  /* manipulção de items e paginas */
  const [currentPageTodos, setCurrentPageTodos] = useState(1);
  const [currentPagePagas, setCurrentPagePagas] = useState(1);
  const [currentPageEstornados, setCurrentPageEstornados] = useState(1);
  const itemsPerPage = 10;

  // Calcular o índice dos itens a serem exibidos com base na página atual
  const startIndexTodos = (currentPageTodos - 1) * itemsPerPage;
  const startIndexPagos = (currentPagePagas - 1) * itemsPerPage;
  const startIndexEstornados = (currentPageEstornados - 1) * itemsPerPage;
  const endIndexTodos = startIndexTodos + itemsPerPage;
  const endIndexPagos = startIndexPagos + itemsPerPage;
  const endIndexEstornados = startIndexEstornados + itemsPerPage;

  // Funções para navegar entre as páginas

  const handlePreviousPage = (currentPage, setPage) => {
    if (currentPage > 1) setPage(currentPage - 1);
  };

  const handleNextPage = (currentPage, totalPages, setPage) => {
    if (currentPage < totalPages) setPage(currentPage + 1);
  };

  const toast = useToast();

  const { setDataUser, loading, authInfo, setNotiMessage } =
    useContext(AuthContext);
  const { dataUser } = authInfo;
  let navigate = useNavigate();
  const token = authInfo?.dataUser?.token;

  const fetchTaxas = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVIDOR}/configuracao`, {
        headers: {
          "x-access-token": token,
          "content-type": "application/json",
        }
      });

      const configs = response.data;
      const creditoConfig = configs.find(config => config.chave === 'taxaCredito');
      const debitoConfig = configs.find(config => config.chave === 'taxaDebito');
      const pixConfig = configs.find(config => config.chave === 'taxaPix');

      setTaxaCredito(creditoConfig ? Number(creditoConfig.valor) : 0);
      setTaxaDebito(debitoConfig ? Number(debitoConfig.valor) : 0);
      setTaxaPix(pixConfig ? Number(pixConfig.valor) : 0);
    } catch (error) {
      toast({
        title: "Erro ao carregar taxas",
        description: "Não foi possível carregar as taxas configuradas.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right"
      });
    }
  };

  const dataData = () => {
    axios
      .get(
        dataUser.key === "ADMIN"
          ? `${process.env.REACT_APP_SERVIDOR}/maquinas-adm`
          : `${process.env.REACT_APP_SERVIDOR}/maquinas`,
        {
          headers: {
            "x-access-token": token,
            "content-type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setTotalFornecedores(res.data);
        } else {
          throw new Error();
        }
      })
      .catch((err) => {
        if ([401, 403].includes(err.response.status)) {
          // setNotiMessage('A sua sessão expirou, para continuar faça login novamente.');
          setNotiMessage({
            type: "error",
            message:
              "A sua sessão expirou, para continuar faça login novamente.",
          });
          setDataUser(null);
        }
      });
  };

  useEffect(() => {
    dataData();
    fetchTaxas();
  }, []);

  useEffect(() => {
    const getRows = () => {
      const pagamentos = [];

      // Combina todos os pagamentos de todos os clientes
      machines.forEach(({ nome, descricao, payments }) => {
        if (payments && Array.isArray(payments.pagamentos)) {
          payments.pagamentos.forEach((pagamento) => {
            pagamentos.push({ cliente: nome, descricao, ...pagamento });
          });
        } else {
          console.warn(
            `Os pagamentos da máquina ${nome} não estão no formato esperado.`,
            payments
          );
        }
      });

      // Ordena os pagamentos por data
      pagamentos.sort((a, b) => new Date(b.data) - new Date(a.data));

      // Atualiza o estado com os pagamentos ordenados
      setTodosPagamentos(pagamentos);
    };

    getRows();
  }, [machines]); // Reexecuta sempre que 'machines' mudar

  useEffect(() => {
    const fetchMaquinas = async (maquinas) => {
      const promises = maquinas.map((maquina) =>
        axios
          .get(
            dataUser.key === "ADMIN"
              ? `${process.env.REACT_APP_SERVIDOR}/pagamentos-adm/${maquina.id}`
              : `${process.env.REACT_APP_SERVIDOR}/pagamentos/${maquina.id}`,
            {
              headers: {
                "x-access-token": token,
                "content-type": "application/json",
              },
            }
          )
          .then((response) => {
            // Verifica se response.data está no formato esperado
            if (response.data && Array.isArray(response.data.pagamentos)) {
              return {
                nome: maquina.nome,
                descricao: maquina.descricao,
                payments: response.data,
              };
            } else {
              console.warn(
                `Os dados da máquina ${maquina.nome} não estão no formato esperado.`,
                response.data
              );
              return {
                nome: maquina.nome,
                descricao: maquina.descricao,
                payments: { pagamentos: [] },
              }; // Retorna vazio para pagamentos
            }
          })
          .catch((error) => {
            console.error(`Erro ao buscar a máquina ${maquina.nome}:`, error);
            return {
              nome: maquina.nome,
              descricao: maquina.descricao,
              payments: { pagamentos: [] },
            }; // Retorna vazio em caso de erro
          })
      );

      const resultados = await Promise.all(promises);
      return resultados;
    };

    fetchMaquinas(totalFornecedores).then((resultados) => {
      setMachines(resultados);
    });
  }, [token, totalFornecedores]);

  const pagamentosEstornados = todosPagamentos.filter(
    ({ estornado }) => estornado === true
  );

  const pagamentosNormal = todosPagamentos.filter(
    ({ estornado }) => estornado !== true
  );

  const totalPagesTodos = Math.ceil(todosPagamentos.length / itemsPerPage);
  const totalPagesPagas = Math.ceil(pagamentosNormal.length / itemsPerPage);
  const totalPagesEstornados = Math.ceil(
    pagamentosEstornados.length / itemsPerPage
  );

  const currentItemsPagos = pagamentosNormal.slice(
    startIndexPagos,
    endIndexPagos
  );
  /* const currentItemsEstornados = pagamentosEstornados.slice(
    currentPageEstornados,
    endIndexEstornados
  ); */
  const currentItemsEstornados = pagamentosEstornados.slice(
    startIndexEstornados, // CORRETO
    endIndexEstornados
  );
  const currentItemsTodos = todosPagamentos.slice(
    startIndexTodos,
    endIndexTodos
  );

  return (
    <Flex flexDirection="column" minH="100vh">
      <Flex
        justifyContent="space-between"
        alignItems="flex-start"
        mb={6}
        flexDirection={{ base: "column", md: "row" }}
        gap={{ base: "20px", md: "0" }}
      >
        <Text color="white" fontSize="24px">
          Vendas
        </Text>

        <Flex flexDirection="column" gap="20px">
          <Flex
            gap="20px"
            flexWrap={{ base: "wrap", md: "nowrap" }}
            justifyContent={{ base: "right", md: "flex-start" }}
          >
            <Flex
              backgroundColor="#1F2630"
              padding="10px 20px"
              borderRadius="12px"
              alignItems="center"
              minW="150px"
            >
              <Flex flexDirection="column">
                <Text color="gray.400" fontSize="12px">Taxa Crédito</Text>
                <Text color="white" fontSize="18px" fontWeight="bold">{taxaCredito}%</Text>
              </Flex>
            </Flex>

            <Flex
              backgroundColor="#1F2630"
              padding="10px 20px"
              borderRadius="12px"
              alignItems="center"
              minW="150px"
            >
              <Flex flexDirection="column">
                <Text color="gray.400" fontSize="12px">Taxa Débito</Text>
                <Text color="white" fontSize="18px" fontWeight="bold">{taxaDebito}%</Text>
              </Flex>
            </Flex>

            <Flex
              backgroundColor="#1F2630"
              padding="10px 20px"
              borderRadius="12px"
              alignItems="center"
              minW="150px"
            >
              <Flex flexDirection="column">
                <Text color="gray.400" fontSize="12px">Taxa PIX</Text>
                <Text color="white" fontSize="18px" fontWeight="bold">{taxaPix}%</Text>
              </Flex>
            </Flex>
          </Flex>
          <Text color={"grey"} fontSize={"70%"}>Taxas configuradas pelo administrador</Text>
        </Flex>
      </Flex>      <Tabs>
        <TabList borderColor="#1F2630">
          <Tab
            color="white"
            _selected={{ borderColor: "white" }}
            fontSize="14px"
          >
            Todas{" "}
            <span
              style={{
                marginLeft: "10px",
                padding: "5px 10px",
                backgroundColor: "white",
                borderRadius: "8px",
                fontSize: "12px",
                color: "black",
              }}
            >
              {todosPagamentos.length}
            </span>
          </Tab>
          <Tab
            color="white"
            _selected={{ borderColor: "white" }}
            fontSize="14px"
          >
            Pagas{" "}
            <span
              style={{
                marginLeft: "10px",
                padding: "5px 10px",
                backgroundColor: "#21BD52",
                borderRadius: "8px",
                fontSize: "12px",
              }}
            >
              {pagamentosNormal.length}
            </span>
          </Tab>
          <Tab
            color="white"
            _selected={{ borderColor: "white" }}
            fontSize="14px"
          >
            Estornadas
            <span
              style={{
                marginLeft: "10px",
                padding: "5px 10px",
                backgroundColor: "#F45031",
                borderRadius: "8px",
                fontSize: "12px",
              }}
            >
              {pagamentosEstornados.length}
            </span>
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <TableContainer>
              <Table size="sm">
                <Thead>
                  <Tr>
                    <Th borderColor="#1F2630">Status</Th>
                    <Th borderColor="#1F2630">Cliente</Th>
                    <Th borderColor="#1F2630">Meio de pagamento</Th>
                    <Th borderColor="#1F2630">Preço</Th>
                    <Th borderColor="#1F2630">Taxa</Th>
                    <Th borderColor="#1F2630">Total</Th>
                    <Th borderColor="#1F2630">Data</Th>
                    <Th borderColor="#1F2630">Loja</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {currentItemsTodos.map(
                    ({ cliente, descricao, valor, estornado, tipo, data, taxas }) => (
                      <Tr>
                        <Td color="white" borderColor="#1F2630">
                          <Tag
                            color={estornado ? "#F45031" : "#21BD52"}
                            style={{ borderRadius: "8px" }}
                          >
                            {estornado ? "Estornada" : "Paga"}
                          </Tag>
                        </Td>
                        <Td borderColor="#1F2630" color="white">
                          {cliente}
                        </Td>
                        <Td color="white" borderColor="#1F2630">
                          {" "}
                          {tipo === "bank_transfer"
                            ? "PIX"
                            : tipo === "CASH"
                              ? "Espécie"
                              : tipo === "debit_card"
                                ? "Débito"
                                : tipo === "credit_card"
                                  ? "Crédito"
                                  : tipo === "account_money"
                                    ? "PIX"
                                    : "-"}
                        </Td>
                        <Td color="white" borderColor="#1F2630">
                          {Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          }).format(valor)}
                        </Td>
                        <Td color="#F45031" borderColor="#1F2630">
                          {tipo === "CASH" ? "-" : Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          }).format(taxas)}
                        </Td>
                        <Td color="#21BD52" borderColor="#1F2630">
                          {tipo === "CASH" ? Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          }).format(valor) : (valor - taxas).toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          })}
                        </Td>                        <Td color="white" borderColor="#1F2630">
                          <span
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {" "}
                            {format(new Date(data), "dd/MM/yyyy")}{" "}
                            <span style={{ marginLeft: "10px", color: "gray" }}>
                              {new Date(data).toLocaleTimeString("pt-BR", {
                                hour: "2-digit",
                                minute: "2-digit",
                              })}
                            </span>
                          </span>
                        </Td>
                        <Td color="white" borderColor="#1F2630">
                          {descricao}
                        </Td>
                      </Tr>
                    )
                  )}
                </Tbody>
              </Table>
            </TableContainer>
            <Flex justifyContent="flex-end" alignItems="center" gap="20px">
              <ArrowLeftOutlined
                onClick={() =>
                  handlePreviousPage(currentPageTodos, setCurrentPageTodos)
                }
                style={{ color: "white" }}
              />
              <Text marginTop="15px">{currentPageTodos}</Text>
              <ArrowRightOutlined
                onClick={() =>
                  handleNextPage(
                    currentPageTodos,
                    totalPagesTodos,
                    setCurrentPageTodos
                  )
                }
                style={{ color: "white" }}
              />
            </Flex>
          </TabPanel>
          <TabPanel>
            <TableContainer>
              <Table size="sm">
                <Thead>
                  <Tr>
                    <Th borderColor="#1F2630">Status</Th>
                    <Th borderColor="#1F2630">Cliente</Th>
                    <Th borderColor="#1F2630">Meio de pagamento</Th>
                    <Th borderColor="#1F2630">Preço</Th>
                    <Th borderColor="#1F2630">Taxa</Th>
                    <Th borderColor="#1F2630">Total</Th>
                    <Th borderColor="#1F2630">Data</Th>
                    <Th borderColor="#1F2630">Loja</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {currentItemsPagos.map(
                    ({ cliente, descricao, valor, estornado, tipo, data, taxas }) => (
                      <Tr>
                        <Td color="white" borderColor="#1F2630">
                          <Tag
                            color={estornado ? "#F45031" : "#21BD52"}
                            style={{ borderRadius: "8px" }}
                          >
                            {estornado ? "Estornada" : "Paga"}
                          </Tag>
                        </Td>
                        <Td borderColor="#1F2630" color="white">
                          {cliente}
                        </Td>
                        <Td color="white" borderColor="#1F2630">
                          {" "}
                          {tipo === "bank_transfer"
                            ? "PIX"
                            : tipo === "CASH"
                              ? "Espécie"
                              : tipo === "debit_card"
                                ? "Débito"
                                : tipo === "credit_card"
                                  ? "Crédito"
                                  : tipo === "account_money"
                                    ? "PIX"
                                    : "-"}
                        </Td>
                        <Td color="white" borderColor="#1F2630">
                          {Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          }).format(valor)}
                        </Td>
                        <Td color="#F45031" borderColor="#1F2630">
                          {tipo === "CASH" ? "-" : Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          }).format(taxas)}
                        </Td>
                        <Td color="#21BD52" borderColor="#1F2630">
                          {tipo === "CASH" ? Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          }).format(valor) : (valor - taxas).toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          })}
                        </Td>
                        <Td color="white" borderColor="#1F2630">
                          <span
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {" "}
                            {format(new Date(data), "dd/MM/yyyy")}{" "}
                            <span style={{ marginLeft: "10px", color: "gray" }}>
                              {new Date(data).toLocaleTimeString("pt-BR", {
                                hour: "2-digit",
                                minute: "2-digit",
                              })}
                            </span>
                          </span>
                        </Td>
                        <Td color="white" borderColor="#1F2630">
                          {descricao}
                        </Td>
                      </Tr>
                    )
                  )}
                </Tbody>              </Table>
            </TableContainer>
            <Flex justifyContent="flex-end" alignItems="center" gap="20px">
              <ArrowLeftOutlined
                onClick={() =>
                  handlePreviousPage(currentPagePagas, setCurrentPagePagas)
                }
                style={{ color: "white" }}
              />
              <Text marginTop="15px">{currentPagePagas}</Text>
              <ArrowRightOutlined
                onClick={() =>
                  handleNextPage(
                    currentPagePagas,
                    totalPagesPagas,
                    setCurrentPagePagas
                  )
                }
                style={{ color: "white" }}
              />
            </Flex>
          </TabPanel>
          <TabPanel>
            <TableContainer>
              <Table size="sm">
                <Thead>
                  <Tr>
                    <Th borderColor="#1F2630">Status</Th>
                    <Th borderColor="#1F2630">Cliente</Th>
                    <Th borderColor="#1F2630">Meio de pagamento</Th>
                    <Th borderColor="#1F2630">Preço</Th>
                    <Th borderColor="#1F2630">Taxa</Th>
                    <Th borderColor="#1F2630">Total</Th>
                    <Th borderColor="#1F2630">Data</Th>
                    <Th borderColor="#1F2630">Loja</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {currentItemsEstornados.map(
                    ({ cliente, descricao, valor, estornado, tipo, taxas, data }) => (
                      <Tr>
                        <Td color="white" borderColor="#1F2630">
                          <Tag
                            color={estornado ? "#F45031" : "#21BD52"}
                            style={{ borderRadius: "8px" }}
                          >
                            {estornado ? "Estornada" : "Paga"}
                          </Tag>
                        </Td>
                        <Td borderColor="#1F2630" color="white">
                          {cliente}
                        </Td>
                        <Td color="white" borderColor="#1F2630">
                          {" "}
                          {tipo === "bank_transfer"
                            ? "PIX"
                            : tipo === "CASH"
                              ? "Espécie"
                              : tipo === "debit_card"
                                ? "Débito"
                                : tipo === "credit_card"
                                  ? "Crédito"
                                  : tipo === "account_money"
                                    ? "PIX"
                                    : "-"}
                        </Td>
                        <Td color="white" borderColor="#1F2630">
                          {Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          }).format(valor)}
                        </Td>
                        <Td color="#F45031" borderColor="#1F2630">
                          {tipo === "CASH" ? "-" : Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          }).format(taxas)}
                        </Td>
                        <Td color="#21BD52" borderColor="#1F2630">
                          {tipo === "CASH" ? Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          }).format(valor) : (valor - taxas).toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          })}
                        </Td>
                        <Td color="white" borderColor="#1F2630">
                          <span
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {" "}
                            {format(new Date(data), "dd/MM/yyyy")}{" "}
                            <span style={{ marginLeft: "10px", color: "gray" }}>
                              {new Date(data).toLocaleTimeString("pt-BR", {
                                hour: "2-digit",
                                minute: "2-digit",
                              })}
                            </span>
                          </span>
                        </Td>
                        <Td color="white" borderColor="#1F2630">
                          {descricao}
                        </Td>
                      </Tr>
                    )
                  )}
                </Tbody>              </Table>
            </TableContainer>
            <Flex justifyContent="flex-end" alignItems="center" gap="20px">
              <ArrowLeftOutlined
                onClick={() =>
                  handlePreviousPage(
                    currentPageEstornados,
                    setCurrentPageEstornados
                  )
                }
                style={{ color: "white" }}
              />
              <Text marginTop="15px">{currentPageEstornados}</Text>
              <ArrowRightOutlined
                onClick={() =>
                  handleNextPage(
                    currentPageEstornados,
                    totalPagesEstornados,
                    setCurrentPageEstornados
                  )
                }
                style={{ color: "white" }}
              />
            </Flex>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
}
